import React, { useEffect } from 'react';

import LogoColasWithTitle from '../../assets/images/logo-text.inline.svg';
import MediaPageHeaderDesign from '../../assets/images/mediapage-design-header.inline.svg';
import Layout from '../../Layout';

import './styles.scss';

const classNames = require('classnames');

// markup
const NotFoundPage = () => {
  useEffect(() => {
    window.location.replace('/'); // redirecting to home page
  }, []);

  return (
    <Layout>
      <div className={classNames('page_template', 'page_error')}>
        <MediaPageHeaderDesign className="media_top_design" />
        <div className="wrapper_page">

          <div className='container_error'>
            <div className='logo_container'>
              <LogoColasWithTitle className="logo_colas" />
            </div>

          </div>
        </div>
      </div>
    </Layout >

  );
};

export default NotFoundPage;
